import { setPostTitle } from "./_text-splitting";
import { intersectionObserve } from "./_intersection-observer";

var $pageLink = $('.page-link');
if ($pageLink.length > 0) {
    if ($(window).width() < 768) {
        setPostTitle($pageLink.find('.title-wrapper-mobile').children('.post-title'));
    }
    else {
       setPostTitle($pageLink.find('.title-wrapper-desktop').children('.post-title'));
    }
}
var pageLink = document.querySelector('.page-link .wrapper');
if (pageLink) {
    intersectionObserve(pageLink, 0, 0, function (element, intersectionRatio) {
        if (intersectionRatio > 0) {
            element.classList.add('animate');
        }
    });
}