var $quote = $('.quote-image .quote-wrapper .quote');
var $homeImages = $('body.home .quote-image .fixed-container');

if ($quote.length > 0) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var speedText = 0.15;

        $quote.each(function () {
            var $this = $(this);
            var topOffset;
            var $quotePosition = $this.parent('.quote-wrapper').attr('data-position');
            if ($quotePosition == 'top') {
                topOffset = $this.offset().top - 700;
            }
            else if ($quotePosition == 'center') {
                if ($(window).width() < 768) {
                    topOffset = $this.offset().top - 500;
                }
                else {
                    topOffset = $this.offset().top - 700;
                }
            }
            else {
                if ($(window).width() < 768) {
                    topOffset = $this.offset().top - 500;
                }
                else {
                    topOffset = $this.offset().top - 1000;
                }
            }
            var $quoteImages = $this.siblings('.quote-images');
            var height = $this.parents('.quote-image').outerHeight();
            var parallaxSize = (scroll - topOffset) * speedText;

            // Prevent parallax when scrolled past elememt
            if (scroll > topOffset + (height / 1.5)) {
                return;
            }

            $this.css({
                'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize + 'px)') : ''
            });
            if ($quoteImages.length > 0) {
                $quoteImages.css({
                    'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize + 'px)') : ''
                });
            }
        });
    });
}

var lastScrollTop = 0;

if ($homeImages.length > 0) {
    var $offsetTopFirst;
    var $offsetTopLast;
    var $imageHeight;
    var $textColumns = $('body.home .text-columns');
    var $textColumnsHeight = $textColumns.outerHeight();
    setTimeout(function () {
        $homeImages.each(function () {
            $offsetTopFirst = $homeImages.eq(0).offset().top;
            $offsetTopLast = $homeImages.eq(1).offset().top;
        });
    }, 100);
    setTimeout(function () {
        $imageHeight = $homeImages.eq(1).outerHeight();
        $homeImages.css({ 'min-height': $imageHeight });
    }, 100);
    $(window).scroll(function () {
        var scroll = $(this).scrollTop();
        if (scroll < lastScrollTop) { // Scrolling up
            if (scroll < ($offsetTopLast + $imageHeight)) {
                $homeImages.eq(1).css({ 'z-index': -1, 'position': 'fixed', 'left': 0, 'top': 0, 'margin-top': '0' });
            }
            if (scroll < $offsetTopLast) {
                $homeImages.eq(0).css({ 'position': 'fixed', 'left': 0, 'top': 0 });
                $homeImages.eq(1).css({ 'position': 'initial', 'margin-top': $imageHeight, 'z-index': 0 });
            }
            if (scroll < $offsetTopFirst) {
                $homeImages.eq(0).css({ 'position': 'initial' });
            }
        }
        else {
            if (scroll > $offsetTopFirst) {
                $homeImages.eq(0).css({ 'position': 'fixed', 'left': 0, 'top': 0 });
                $homeImages.eq(1).css({ 'z-index': 0, 'margin-top': $imageHeight });
            }
            if (scroll > $offsetTopLast) {
                $homeImages.eq(0).css({ 'position': 'static' });
                $homeImages.eq(1).css({ 'z-index': -1, 'position': 'fixed', 'left': 0, 'top': 0, 'margin-top': '0' });
                $textColumns.css({ 'margin-top': $imageHeight });
            }
            if (scroll > $offsetTopLast + $imageHeight) {
                $homeImages.eq(1).css({ 'position': 'absolute', 'top': $imageHeight*2});
                $textColumns.css({ 'margin-top': $imageHeight });
            }
        }
        lastScrollTop = scroll <= 0 ? 0 : scroll;
    });
}