let $header = $('header');
let $menuIcon = $('header .menu-icon');
let $quoteImageSections = $('.quote-image');
let $activeMenuLink = $('header .nav-menu .menu-content ul li.current-menu-item');

if ($quoteImageSections.length > 0) {
    let $headerHeight = $header.outerHeight();
    let $isFrontPage = $('body').hasClass('home') ? true : false;
    
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        $quoteImageSections.each(function() {
            let $this = $(this);
            let $offsetTop = $this.offset().top;
            let $blockHeight = $this.height();
            let $height = 0;
            if($(window).width() < 768) {
                $height = $isFrontPage ? (($blockHeight * 2) + $menuIcon.height()) : $blockHeight;
            }
            else {
                $height = $isFrontPage ? ($blockHeight - $menuIcon.height()) : $blockHeight;
            }

            if (scroll > ($offsetTop - $headerHeight - $menuIcon.height()) && (scroll < (($offsetTop - $headerHeight) + ($height + ($menuIcon.height() * 0.25))))) {
                if(!($isFrontPage && $this.is($('.quote-image').first()))) {
                    $menuIcon.removeClass('anim');
                }
                $menuIcon.addClass('show');
                $this.addClass('active');
            }
            else if($this.hasClass('active')) {
                $this.removeClass('active');
                $menuIcon.addClass('anim');
                $menuIcon.removeClass('show');
                setTimeout(function() {
                    $menuIcon.removeClass('anim');
                }, 300);
            }
        });
    });

}

if ($(window).width() >= 1280) {
    if ($activeMenuLink.length > 0) {
        $('header .site-logo').addClass('hide-line');
        $activeMenuLink.find('.active-line').addClass('show');
    }
}