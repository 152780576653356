var $pointers = $('.pointers');

if ($pointers.length > 0 && $(window).width() >= 768 && !$pointers.hasClass('page-template-area')) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var speedText = 0.15;

        $pointers.each(function () {
            var $this = $(this);
            var topOffset;
            if ($(window).width() < 1280) {
                topOffset = $this.offset().top - 500;
            }
            else {
                topOffset = $this.offset().top - 700;
            }
            var height = $this.outerHeight();
            var parallaxSize = (scroll - topOffset) * speedText;

            // Prevent parallax when scrolled past elememt
            if ($(window).width() < 1280 && scroll > topOffset + (height / 1.75)) {
                return;
            }
            else if (scroll > topOffset + height) {
                return;
            }
            if (!$this.parents('body').hasClass('kompetanse') && !$this.parents('body').hasClass('expertise')) {
                $this.css({
                    'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize + 'px)') : ''
                });
            }
        });
    });
}