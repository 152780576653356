let $header = $('header');
let $menuIcon = $('header .menu-icon');
let $newsPreview = $('.news-preview');

if ($newsPreview.length > 0 && $(window).width() >= 768) {
    let $headerHeight = $header.outerHeight();
    
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        $newsPreview.each(function() {
            let $this = $(this);
            let $offsetTop = $this.offset().top;
            let $blockHeight = $this.height();
            if (scroll > ($offsetTop - $headerHeight - $menuIcon.height()) && (scroll < (($offsetTop - $headerHeight) + ($blockHeight + $menuIcon.height())))) {
                $menuIcon.addClass('show');
                $this.addClass('active');
            }
            else if($this.hasClass('active')) {
                $this.removeClass('active');
                $menuIcon.removeClass('anim show');
            }
        });
    });

}