var $menuIcon = $('header .menu-wrapper .menu-button');
var $header = $('header');
var $body = $('body');
var $duppIcon = $('header .menu-icon');
var $navMenu = $('header .menu-wrapper .nav-menu');

if ($menuIcon) {
    $menuIcon.click(function () {
        if ($menuIcon.hasClass('menu-open')) {
            $menuIcon.removeClass('menu-open');
            $header.removeClass('show-menu');
            $body.removeClass('no-scroll-y');
            $duppIcon.removeClass('menu-open');
            $duppIcon.css({
                'top': $header.outerHeight(),
            })
            $navMenu.css({
                'max-height': '0'
            })
        }
        else {
            $menuIcon.addClass('menu-open');
            $header.addClass('show-menu');
            $body.addClass('no-scroll-y');
            $navMenu.css({
                'max-height': ((16 * 6) + $('header .menu-content').outerHeight())
            })
            setTimeout(function () {
                $duppIcon.css({
                    'top': $header.outerHeight(),
                })
            }, 310);
            setTimeout(function () {
                $duppIcon.addClass('menu-open');
            }, 310);
        }
    });
}

$(window).resize(function () {
    if ($(window).width() >= 900) {
        $('header').removeClass('show-menu');
    }
});