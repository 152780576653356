import { intersectionObserve } from "./_intersection-observer";
import { setPostTitle } from "./_text-splitting";

    let $postTitle = $('body.single-post .split-title');
    if($postTitle) {
        setPostTitle($postTitle);
    }

    let $singlePost = $('body.single-post');
    if ($singlePost.length > 0) {
        $('header .nav-menu .menu-content .menu-list li.nyheter a .active-line').css('opacity', 1);
    }

    let body = document.querySelector('body.single-post');
    if (body) {
        let article = body.querySelector('article');
        intersectionObserve(article, 0, 0, function (element, intersectionRatio) {
            if (intersectionRatio > 0) {
                element.classList.add('animate');
            }
        });
    }
