const $imagesCount1 = $('.image-and-text .wrapper.count-1 .images-wrapper');
const $imagesCount2 = $('.image-and-text .wrapper.count-2 .images-wrapper');
let lastScrollTop = 0;
const $imagesBlocks = $('.image-and-text');

if ($imagesCount1.length > 0 || $imagesCount2.length > 0) {
    if ($(window).width() >= 768) {

        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            let speedCircle = 0.25;
            let speedText = 0.3;

            $imagesCount1.each(function() {
                $(this).find('.image').each(function () {
                    let $this = $(this);
                    let topOffset = $this.offset().top - 700;
                    let height = $this.outerHeight();
                    let parallaxSize = (scroll - topOffset) * speedText;

                    // Prevent parallax when scrolled past elememt
                    if ($(window).width() < 1280 && scroll > topOffset + (height)) {
                        return;
                    }
                    else if ($(window).width() >= 1280 && scroll > topOffset + (height / 2)) {
                        return;
                    }

                    $this.css({
                        'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize + 'px)') : ''
                    });
                });
            });

            $imagesCount2.each(function() {
                let $imageFirst = $(this).find('.image').first();
                let topOffset = $imageFirst.offset().top - 900;
                let height = $imageFirst.outerHeight();
                let parallaxSize = (scroll - topOffset) * speedText;
                let $imageLast = $(this).find('.image').last();
                let topOffset2 = $imageLast.offset().top - 800;
                let parallaxSize2 = (scroll - topOffset2) * speedCircle;

                // Prevent parallax when scrolled past elememt
                if ($(window).width() < 1280 && scroll > topOffset + (height / 2)) {
                    return;
                }
                else if ($(window).width() >= 1280 && scroll > topOffset + (height / 2.5)) {
                    return;
                }

                $imageFirst.css({
                    'transform': scroll >= topOffset ? ('translateY(' + parallaxSize + 'px)') : ''
                });
                $imageLast.css({
                    'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize2 + 'px)') : ''
                });

            });
        });
    }
    else {

        let $offsetTop, $imageHeight, $textHeight;
        let lastScrollTop2 = 0;

        $(window).scroll(function () {
            let scroll = $(this).scrollTop();

            $imagesBlocks.each(function(){
                $this = $(this);
                $offsetTop = $this.offset().top;
                $imageHeight = $this.find('.image').first().outerHeight();
                $textHeight = $this.find('.text-content').outerHeight();
                $this.css({'min-height': $imageHeight});
                $count = 1;
                let $hasOneNext = false;
                let $hasTwoNext = false;
                let $hasPrev = false;
                if($this.next().find('.wrapper').hasClass('count-1')) {
                    $hasOneNext = true;
                }
                if($this.next().find('.wrapper').hasClass('count-2')) {
                    $hasTwoNext = true;
                }
                if($this.prev().hasClass('image-and-text')) {
                    $hasPrev = true;
                }
                if($(this).find('.wrapper.count-2').length > 0) {
                    $count = 2;
                    $this.css({'min-height': $imageHeight * 2});
                }

                if($count == 1) {

                    if(scroll > $offsetTop + (!($hasOneNext || $hasTwoNext) ? $imageHeight : $imageHeight * 2) + $textHeight) {
                        $this.find('.image').css({'position': 'relative', 'z-index': 0, 'margin-top': (!($hasOneNext || $hasTwoNext) ? $imageHeight + $textHeight : 0), 'margin-bottom': 0});
                        $this.find('.text-content').css({'position': 'static', 'margin-top': 0});
                        if(!($hasOneNext || $hasTwoNext)) {
                            $this.find('.text-content').css({'position': 'absolute', 'top': 0});
                        }
                    }
                    else if(scroll > $offsetTop) {
                        $this.find('.image').css({'position': 'fixed', 'top': 0, 'left': 0, 'z-index': -1, 'margin-top': 0});
                        $this.find('.text-content').css({'position': 'static', 'margin-top': $imageHeight, 'margin-bottom': $imageHeight});
                    }
                    else if(scroll < $offsetTop) {
                        $this.find('.image').css({'position': 'relative', 'z-index': 0});
                    }
                }

                else {
                    if(scroll > $offsetTop + (!($hasOneNext || $hasTwoNext) ? $imageHeight * 2 : $imageHeight * 3) + $textHeight) {
                        $this.find('.image').last().css({'position': 'relative', 'z-index': 0, 'margin-top': $imageHeight + $textHeight, 'margin-bottom': 0});
                        $this.find('.text-content').css({'position': 'absolute', 'top': 0});
                    }
                    else if (scroll < lastScrollTop2 && scroll > $offsetTop - $imageHeight && scroll < $offsetTop) {
                        $this.find('.image').first().css({'position': 'relative', 'top': 0, 'left': 0, 'z-index': -1});
                        $this.find('.image').last().css({'position': 'relative', 'margin-top': $imageHeight});
                    }
                    else if (scroll < lastScrollTop2 && scroll > $offsetTop && scroll < $offsetTop + $imageHeight ) {
                        $this.find('.image').first().css({'position': 'fixed', 'top': 0, 'left': 0, 'z-index': -1});
                        $this.find('.image').last().css({'position': 'relative', 'margin-top': $imageHeight});
                    }
                    else if(scroll > $offsetTop + (scroll < lastScrollTop2 ? 0 : $imageHeight)) {
                        $this.find('.image').first().css({'position': 'relative', 'z-index': 0, 'margin-bottom': $imageHeight});
                        $this.find('.image').last().css({'position': 'fixed', 'top': 0, 'left': 0, 'z-index': -1, 'margin-top': 0, 'margin-bottom': $imageHeight});
                        $this.find('.text-content').css({'position': 'static', 'margin-top': 0, 'margin-bottom': $imageHeight});
                    }
                    else if(scroll > $offsetTop) {
                        $this.find('.image').first().css({'position': 'fixed', 'top': 0, 'left': 0, 'z-index': -1});
                        $this.find('.image').last().css({'position': 'relative', 'margin-top': $imageHeight});
                        $this.find('.text-content').css({'position': (scroll < lastScrollTop2 ? 'absolute' : 'static'), 'margin-top': (scroll < lastScrollTop2 ? $imageHeight * 2 : $imageHeight)});
                    }
                    else if (scroll < $offsetTop) {
                        $this.find('.image').last().css({'position': 'relative', 'z-index': 0});
                    }
                }
            
            });
            
            lastScrollTop2 = scroll;
        });
    }

}

let $pattern = $('.image-and-text .pattern-wrapper');
if ($pattern.length > 0) {

    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        let speedPattern = 0.25;

        let topOffset = $pattern.offset().top - 700;
        let height = $pattern.outerHeight();
        let parallaxSize = (scroll - topOffset) * speedPattern;

        if (scroll > topOffset + height) {
            return;
        }

        $pattern.find('svg').css({
            'transform': scroll >= topOffset ? ('translateX(' + parallaxSize + 'px)') : ''
        });
    });
}