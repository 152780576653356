import Splitting from "/node_modules/splitting";

export const setPostTitle = function ($element) {
    if($element && $element.length > 0) {
        let result = Splitting({ target: $element, by: 'lines' });
        let lines = result[0]["lines"];
    
        for(let i=0; i < lines.length; i++) {
            let wrapper = document.createElement('div');
            wrapper.setAttribute('class', 'split-line split-line-' + i);
            $element[0].append(wrapper);
            for(let j=0; j < lines[i].length; j++) {
                lines[i][0].classList.add('first-word');
                document.querySelector('.split-line-' + i).append(lines[i][j]);
            }
        }
    }
}