// Smooth scroll to anchor links
if($(window).width() < 768) {
    $('a').click(function(e) {
        scrollToAnchor(this.href, e, 140);
    });
}
else if ($(window).width() < 1280) {
    $('a').click(function(e) {
        scrollToAnchor(this.href, e, 170);
    });
}
else {
    $('a').click(function(e) {
        scrollToAnchor(this.href, e, 200);
    });
}

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
  /*   if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
        return;
    } */

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        //window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;

$offsetScroll = 200;
if($(window).width() < 768) {
    $offsetScroll = 80;
}
else if ($(window).width() < 1280) {
    $offsetScroll = 170;
}

if (window.location.hash) {
    var target = window.location.hash,
    target = target.replace('#', '');
    window.location.hash = "";
    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $("#" + target).offset().top - $offsetScroll
        }, 300);
    }, 900)
}