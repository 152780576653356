$('.block-hero .employees-areas a:first').addClass('active');
var $tagButton = $('.block-hero .employees-areas a');

$tagButton.click(function (e) {
    e.preventDefault();
    var slug = this.getAttribute('data-slug');
    $tagButton.removeClass('active');
    $(this).addClass('active');
    if (slug == 'alle') {
        $('.employees-preview .tease-employees').show();
    }
    else {
        $('.employees-preview .employees-wrapper .tease-employees').hide();
        $('.employees-preview .employees-wrapper .tease-employees.' + slug).show();
    }
});
