let $offices = $('.offices .office-item');

if ($offices.length > 0) {
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        let speedText = 0.1;

        $offices.each(function(index) {
            let $image = $(this).find('.office-image .image');

            let topOffset = $image.offset().top - 500;
            let parallaxSize = (scroll - topOffset) * speedText;

            if (parallaxSize > (2 * 16)) {
                return;
            }

            $image.css({
                'transform': scroll >= topOffset ? ('translateY(' + parallaxSize + 'px)') : ''
            });
        });
    });
}

if ($offices.length > 0) {
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        let speedImage = 0.15;
        let topOffsetMap;
    
        $offices.each(function(index) {
            let $map = $(this).find('.office-wrapper .image');
            
            if ($(window).width() < 768) {
                topOffsetMap = $map.offset().top - 500;
            }
            else if ($(window).width() < 1280) {
                topOffsetMap = $map.offset().top - 700;
            }
            else {
                if($(window).height() < 900) {
                    topOffsetMap = $map.offset().top - 1200;
                }
                else {
                    topOffsetMap = $map.offset().top - 1000;
                }
            }

            let parallaxSize = (scroll - topOffsetMap) * speedImage;


            if ($(window).width() < 768 && parallaxSize > (2 * 16)) {
                return;
            }
            else if ($(window).width() < 1280 && parallaxSize > (4 * 16)) {
                return;
            }
            else if (parallaxSize > (10 * 16)) {
                return;
            }

            $map.css({
                'transform': scroll >= topOffsetMap ? ('translateY(-' + parallaxSize + 'px)') : ''
            });

        });
    });
}