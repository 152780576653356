import bodymovin from 'lottie-web';
import { intersectionObserve } from "./_intersection-observer";

var animations = document.querySelectorAll('.hero-animation');
var animation;

if (animations) {
    function setup() {
        animations.forEach(element => loadAnimation(element));
    }

    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);

    function loadAnimation(element) {
        if (element.classList.contains('loaded')) {
            return;
        }

        var data = element.querySelector('.data code');

        animation = bodymovin.loadAnimation({
            container: element, // Required
            animationData: JSON.parse(data.innerHTML),
            renderer: 'svg', // Required
            loop: false, // Optional
            autoplay: true, // Optional
            name: "", // Name for future reference. Optional.
        });

        element.classList.add('loaded');
    }
}

var $areaLinks = $('.block-hero .bottom-text .areas a');
var $headerHeight = $('header').outerHeight();

$areaLinks.click(function () {
    $('.block-hero .bottom-text .areas a.active').addClass('visited');
    $areaLinks.removeClass('active');
    $(this).addClass('active');
});

var $areasMenu = $('.block-hero .bottom-text .areas');
var $areasContainer = $('.block-hero .text-content');
if ($areasMenu.length > 0) {
    setTimeout(function () {
        $('.block-hero .text-content .bottom-text').css({
            'transform': 'none'
        })
    }, 700);
    var $areasMenuOffsetTop = $areasContainer.offset().top + $('.block-hero .top-text').outerHeight();
    var $height = $areasMenu.height();
    $('.block-hero .bottom-text').css({
        'height': $('.block-hero .bottom-text').height()
    })
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll > ($areasMenuOffsetTop + $height - $headerHeight - 16)) {
            $areasMenu.addClass('set-fixed')
        }
        else {
            $areasMenu.removeClass('set-fixed');
        }
    });
}

var hero = document.querySelector('.block-hero');
if (hero) {
    var heroText = hero.querySelector('.text-content');
    intersectionObserve(heroText, 0, 0, function (element, intersectionRatio) {
        if (intersectionRatio > 0) {
            element.classList.add('animate');
        }
    });
}

$(window).resize(function () {
    if ($areasMenu.length > 0) {
        if ($areasMenu.css('display') === 'none') {
            $('.block-hero .bottom-text').css({
                'height': 0
            })
        }
        else {
            $('.block-hero .bottom-text').css({
                'height': $areasMenu.height()
            })
        }
    }
});
