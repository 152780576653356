import { setPostTitle } from "./_text-splitting";
import { intersectionObserve } from "./_intersection-observer";

let $filterButton = $('.news-section .filter .filter-icon svg');
let $areaLinks = $('.news-section .filter .areas a');
let $firstPostSlug = $('.news-section .news-wrapper .tease-post').first().attr('data-area');
let $topNews = $('.news-section .top-news');
let $moreLink = $('.news-section .news-wrapper a.more-news');

if ($topNews.length > 0) {
    $topNews.find('.post-areas').text($firstPostSlug);

    changeTopNews($('.news-section .news-wrapper .tease-post').first());

    $('.tease-post').each(function (index) {
        if (index > 0 && index < 13) {
            $('.tease-post').eq(index).addClass('show-post');
        }
    });
    if ($('.tease-post.show-post').length == ($('.tease-post').length) - 1) {
        $moreLink.hide();
    }
    else {
        $moreLink.show();
    }
}
$areaLinks.click(function (e) {
    e.preventDefault();
    let $prevSelected = $('.news-section .filter .areas a.active').attr('data-slug');
    $('.news-section .filter .areas a.active').addClass('visited');
    $areaLinks.removeClass('active');
    $(this).addClass('active');
    let slug = this.getAttribute('data-slug');
    let name = this.getAttribute('data-name');

    $topNews.removeClass($prevSelected);
    $topNews.addClass(slug);
    let $selectedPosts = $('.news-section .news-wrapper .tease-post.' + slug);
    let $firstPost = $selectedPosts.first();
    $topNews.find('.post-areas').text(name);

    if ($firstPost.length > 0) {
        changeTopNews($firstPost);
    }
    else {
        changeTopNews('null');
    }

    $('.tease-post').removeClass('show-post');
    $('.tease-post.' + slug).each(function (index) {
        if (index > 0 && index < 13) {
            $('.tease-post.' + slug).eq(index).addClass('show-post');
        }
    });
    if ($('.tease-post.show-post.' + slug).length == ($('.tease-post.' + slug).length) - 1) {
        $moreLink.hide();
    }
    else {
        $moreLink.show();
    }

});

$filterButton.click(function () {
    let $areas = $('.news-section .filter .areas');
    if ($areas.hasClass('show')) {
        $areas.removeClass('show');
    }
    else {
        $areas.addClass('show');
    }
});

function changeTopNews($element) {
    if ($element == 'null') {
        $topNews.find('.post-title').hide();
        $topNews.find('.post-preview').hide();
        $topNews.children('.content-left').find('a.link-with-circle').hide();
        $topNews.children('.content-right').find('img').hide();
    }
    else {
        if ($(window).width() < 768) {
            let $titleContainer = $topNews.find('.title-wrapper-mobile');
            $titleContainer.empty();
            $titleContainer.append('<h1 class="post-title"><a href=""></a></h1>');
            $titleContainer.find('.post-title a').html($element.find('.post-title').html());            
            setPostTitle($topNews.find('.title-wrapper-mobile .post-title a'));            
        }
        else {
            let $titleContainer = $topNews.find('.title-wrapper-desktop');
            $titleContainer.empty();
            $titleContainer.append('<h1 class="post-title"><a href=""></a></h1>');
            $titleContainer.find('.post-title a').html($element.find('.post-title').html());
            setPostTitle($topNews.find('.title-wrapper-desktop .post-title a'));
            $topNews.children('.content-left').find('.post-title a').attr('href', $element.find('a').attr('href'));
        }
        $topNews.find('.post-preview').show();
        $topNews.children('.content-left').find('a.link-with-circle').show();
        $topNews.children('.content-right').find('img').show();
        $topNews.find('.post-preview a').html($element.attr('data-preview'));
        $topNews.find('.post-preview a').attr('href', $element.find('a').attr('href'));
        $topNews.children('.content-left').find('a.link-with-circle').attr('href', $element.find('a').attr('href'));
        $topNews.children('.content-right').find('img').attr('src', $element.find('img').attr('src'));
        $topNews.children('.content-right').find('.image a').attr('href', $element.find('a').attr('href'));
    }
}

$moreLink.click(function (e) {
    e.preventDefault();
    let $slug = $('.filter .areas .area-link a.active').data('slug');
    let $articlesShown = $('.tease-post.show-post.' + $slug);
    let $articlesCount = $('.tease-post.' + $slug);

    if(!$slug){
        $articlesShown = $('.tease-post.show-post');
        $articlesCount = $('.tease-post');

    }

    if ($articlesShown.length <= $articlesCount.length) {
        if($slug) {
            $('.tease-post.' + $slug).eq($articlesShown.length + 1).addClass('show-post');
            $('.tease-post.' + $slug).eq($articlesShown.length + 2).addClass('show-post');
            $('.tease-post.' + $slug).eq($articlesShown.length + 3).addClass('show-post');
        }else{
            $('.tease-post').eq($articlesShown.length + 1).addClass('show-post');
            $('.tease-post').eq($articlesShown.length + 2).addClass('show-post');
            $('.tease-post').eq($articlesShown.length + 3).addClass('show-post');
        }
    }
    if ($articlesShown.length == $articlesCount.length - 2 || $articlesShown.length == $articlesCount.length - 3 || $articlesShown.length == $articlesCount.length - 4) {
        $moreLink.hide();

    }
});

let topNews = document.querySelector('.top-news');
if (topNews) {
    intersectionObserve(topNews, 0, 0, function (element, intersectionRatio) {
        if (intersectionRatio > 0) {
            element.classList.add('animate');
        }
    });
}