const $block = $('section.events-signup');

if($block.length > 0) {
    let $signUpBtn = $block.find('.sign-up-btn');
    let $modal = $block.find('.form-modal');
    let $closeBtn = $modal.find('.close-btn');
    $signUpBtn.click(function() {
        if(!$modal.hasClass('show')) {
            $modal.addClass('show');
            $block.find('.overlay').addClass('show');
            $('body').addClass('no-scroll');
        }
        else {
            $modal.removeClass('show');
            $block.find('.overlay').removeClass('show');
            $('body').removeClass('no-scroll');
        }
    });
    $closeBtn.click(function() {
        $modal.removeClass('show');
        $block.find('.overlay').removeClass('show');
        $('body').removeClass('no-scroll');
        $modal.find('form')[0].reset();
    });
}