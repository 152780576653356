$('article.tease-post .image').hover(function () {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 51, 1)');
    $(this).find('.post-preview').css({
        'opacity': 1
    });
}, function () {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 0, 0)');
    $(this).find('.post-preview').css({
        'opacity': 0
    });
})