import { intersectionObserve } from "./_intersection-observer";

var footer = document.querySelector('footer');
if(footer) {
    var footerBottom = document.querySelector('.footer-bottom');
    var footerWrapper = footer.querySelector('.wrapper');
    intersectionObserve(footerBottom, 0, 0.75, function (element, intersectionRatio) {
        if (intersectionRatio > 0.75) {
            footerWrapper.classList.add('animate');
        }
        else {
            footerWrapper.classList.remove('animate');
        }
    });
}