var $article = $('.single article.post-type-employees');
var $contentRightHeight;

setTimeout(function () {
    $contentRightHeight = $article.find('.content-right').height();
}, 100);
if ($article.length > 0 && $(window).width() >= 768) {
    setTimeout(function () {
        $article.css({
            'min-height': $contentRightHeight
        })
    }, 100);
}