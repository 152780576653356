import bodymovin from 'lottie-web'
import { intersectionObserve } from "./_intersection-observer";

const animations = document.querySelectorAll('.animation');

let index = 0;

intersectionObserve(animations, 0, 0, (element, intersectionRatio) => {
    (function(el) {
        const dataEl = el.getElementsByClassName('anim')[0];

        if (intersectionRatio > 0 && element.classList.contains('loaded')) {
            return;
        } else if (intersectionRatio <= 0) {
            dataEl.style.display = 'block';
            return;
        }

        element.classList.add('loaded');

        let animation;

        if (el.getAttribute('data-anim-index') === null) {
            el.setAttribute('data-anim-index', index);
            animation = createAnimation(dataEl, index);
            index++;
        }


        var hoverElement = null;
        var parentElement = element;
        while (parentElement) {
            if (parentElement.tagName === 'A') {
                hoverElement = parentElement;
                break
            }

            parentElement = parentElement.parentNode;
        }

        if (hoverElement) {
            $(hoverElement).hover(function () {
                if (!animation.isPaused) {
                    return;
                }

                dataEl.style.display = 'block';

                animation.goToAndPlay(1, true);
            }, function () {
            });
        }
    })(element);

});

const createAnimation = function(element, index) {
    var data = element.querySelector('.data code');
    return bodymovin.loadAnimation({
        container: element, // Required
        animationData: JSON.parse(data.innerHTML),
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        name: "anim-" + index, // Name for future reference. Optional.
    });
};