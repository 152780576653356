var $patternTop = $('.social-media .pattern-top');
var $patternBottom = $('.social-media .pattern-bottom');

$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var speedText = 0.3;

    function patternTop() {
        var patternTopOffset = $patternTop.offset().top - 400;
        var height = $patternTop.outerHeight();
        var parallaxSize = (scroll - patternTopOffset) * speedText;

        // Prevent parallax when scrolled past elememt
        if (scroll > patternTopOffset + height) {
            return;
        }

        $patternTop.css({
            'transform': scroll >= patternTopOffset ? ('translateX(' + parallaxSize + 'px)') : ''
        });
    }

    function patternBottom() {
        var patternBottomOffset = $patternBottom.offset().top - 600;
        var height = $patternBottom.outerHeight();
        var parallaxSize2 = (scroll - patternBottomOffset) * speedText;
    
         // Prevent parallax when scrolled past elememt
         if (scroll > patternBottomOffset + height) {
            return;
        }

        $patternBottom.css({
            'transform': scroll >= patternBottomOffset ? ('translateX(' + parallaxSize2 + 'px)') : ''
        });
    }
    if($patternTop.length > 0) {
        patternTop();
    }
    if($patternBottom.length > 0) {
        patternBottom();
    }
});