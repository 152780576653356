import bodymovin from 'lottie-web'
import { intersectionObserve } from "./_intersection-observer";

const links = document.querySelectorAll('.link-circle');

let index = 0;

intersectionObserve(links, 0, 0, (element, intersectionRatio) => {
    (function(el) {
        const circleEl = el.getElementsByClassName('circle')[0];

        if (intersectionRatio > 0 && element.classList.contains('loaded')) {
            return;
        } else if (intersectionRatio <= 0) {
            circleEl.style.display = 'block';
            element.classList.remove('loaded');

            // Destroy existing animations
            const idx = el.getAttribute('data-circle-index');
            bodymovin.destroy('circle-' + idx);
            el.removeAttribute('data-circle-index');

            return;
        }

        element.classList.add('loaded');

        let circleAnimation;

        if (el.getAttribute('data-circle-index') === null) {
            el.setAttribute('data-circle-index', index);
            circleAnimation = createCircleAnimation(circleEl, index);
            index++;
        }


        var hoverElement = null;
        var parentElement = element;
        while (parentElement) {
            if (parentElement.tagName === 'A') {
                hoverElement = parentElement;
                break
            }

            parentElement = parentElement.parentNode;
        }

        if (hoverElement) {
            $(hoverElement).hover(function () {
                if (!circleAnimation.isPaused) {
                    return;
                }

                circleEl.style.display = 'block';

                circleAnimation.goToAndPlay(1, true);
            }, function () {
            });
        }
    })(element);

});

const createCircleAnimation = function(element, index) {
    return bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/haver_ikon_pluss.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        name: "circle-" + index, // Name for future reference. Optional.
    });
};