import bodymovin from 'lottie-web';

var animations = document.querySelectorAll('.text-columns');
var animation;

if (animations) {
    function setup() {
        animations.forEach(function (el) {
            var element = el.querySelector('.pattern-wrapper');
            if (element) {
                loadAnimation(element);
            }
        });
    }

    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);

    function loadAnimation(element) {
        if (element.classList.contains('loaded')) {
            return;
        }

        animation = bodymovin.loadAnimation({
            container: element, // Required
            path: '/wp-content/themes/grensesnitt/animations/Haver_ark.json',
            renderer: 'svg', // Required
            loop: false, // Optional
            autoplay: true, // Optional
            name: "", // Name for future reference. Optional.
        });

        element.classList.add('loaded');
    }
}

setTimeout(function () {
    var $svgElem = $('.text-columns .pattern-wrapper svg');
    $svgElem.attr('preserveAspectRatio', 'none');
}, 300);


var $pattern = $('.text-columns .pattern-wrapper');
if ($pattern.length > 0 && $(window).width() >= 768) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var speedPattern = 0.25;
        var topOffset = $pattern.offset().top - 700;
        var height = $pattern.outerHeight();
        var parallaxSize = (scroll - topOffset) * speedPattern;

        // Prevent parallax when scrolled past elememt
        if ($(window).width() < 1280 && scroll > topOffset + (height / 1.5)) {
            return;
        }
        else if ($(window).width() >= 1280 && scroll > topOffset + height) {
            return;
        }

        $pattern.css({
            'transform': scroll >= topOffset ? ('translateY(-' + parallaxSize + 'px)') : ''
        });
    });
}